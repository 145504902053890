.engage-table {
  position: relative;
}

.engage-table .engage-table-header-row {
  background-color: var(--engage-colors-bg-white);
  border-bottom: 1px solid rgba(0,0,0,.06);
}

.engage-table .engage-table-header-row .engage-table-cell {
  font-weight: var(--engage-font-weight-bold);
  color: var(--engage-colors-darkgray);
  padding: 0.5rem;
}
.engage-table .engage-table-header-row .engage-table-cell:not(:first-of-type)::before {
  position: absolute;
  margin-left: -0.6rem;
  margin-top: 0.6rem;
  width: 1px;
  height: 1.6em;
  background-color: var(--engage-colors-lightgray);
  transform: translateY(-50%);
  transition: background-color .3s;
  content: "";
}

.engage-table .engage-table-row:hover {
  background-color: var(--engage-colors-lightgray);
}
.engage-table .engage-table-row {
  border-bottom: 1px solid rgba(0,0,0,.06);
}
.engage-table .engage-table-row .engage-table-cell {
  padding: 0.5rem;
}

.engage-table .engage-table-footer-row {
  background-color: var(--engage-colors-bg-white);
}

.engage-table .engage-table-footer-row .engage-table-cell {
  font-weight: var(--engage-font-weight-bold);
  color: var(--engage-colors-darkgray);
  padding: 0.5rem;
}
