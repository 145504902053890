@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:Root {
  --primary: "#0033FF";
}

.engage {

}
