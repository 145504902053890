html,
body {
  font-family: "Raleway", sans-serif;
}
:root {
  /*backgrounds*/
  --engage-colors-bg-default: #f9fafb;
  --engage-colors-bg-white: #fff;
  --engage-colors-bg-transparent: transparent;

  /*colors*/
  --engage-colors-white: #fff;
  --engage-colors-primary: #0033FF;
  --engage-colors-secondary: #001529;
  --engage-colors-lightyellow: #FFF8E1;
  --engage-colors-lightgray: #f3f4f6;
  --engage-colors-darkgray: #595959;
  --engage-colors-lightblue: #e0f2fe;
  --engage-colors-red: #FF4D4F;

  /*font weights*/
  --engage-font-weight-thin: 100;
  --engage-font-weight-normal: 300;
  --engage-font-weight-bold: 500;

  /*font sizes*/
  --engage-font-size-body: 14px;
}
svg {
  vertical-align: baseline;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 0px;
  bottom: 0;
  left: 0px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}

.ant-card,
.ant-btn,
.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-affix-wrapper,
.ant-collapse {
  border-radius: 0.5rem;
}

.ant-card-body {
  width: inherit;
}

.ant-btn,
.ant-input,
.ant-picker,
.ant-menu,
.ant-select:not(.ant-select-customize-input) .ant-select-selector thead tr.ant-card th,
tbody tr td {
  font-family: sans-serif;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.ant-btn,
.ant-picker {
  cursor: pointer;
}

textarea:focus,
input:focus {
  outline: none;
}

code {
  margin: 1rem;
  padding: 1rem;
  background: rgb(240, 240, 240);
  border-radius: 1rem;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-footer {
  border-top: none;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-upload.ant-upload-drag {
  background: white;
}

.bg-transparent-grid {
  background-image: linear-gradient(45deg, #eee 25%, transparent 0, transparent 75%, #eee 0), linear-gradient(45deg, #eee 25%, transparent 0, transparent 75%, #eee 0);
  background-size: 16px 16px;
  background-position: 0 0, 8px 8px;
}

/* Css trick to show child element only when parent is hovered */

/* In normal state */
.parent-hover .child-hover {
  opacity: 0;
  transition: ease-in-out 0.2s;
}

/* When parent is hovered */
.parent-hover:hover .child-hover {
  opacity: 1;
  transition: ease-in-out 0.2s;
}

/* Resizeable div */

.resizeable {
  overflow: auto;
  resize: both;
}
